/* eslint-disable mobx/missing-observer */

'use client';

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { CircleAlert } from 'lucide-react';

export default function ErrorPage() {
  return (
    <div className="mx-3 flex justify-center">
      <Card className="flex min-h-48 w-4/5 flex-col items-center border-0 bg-backdrop shadow-none">
        <CardHeader>
          <CardTitle className="center flex items-center text-2xl">
            <CircleAlert className="mr-2 h-6 w-6" />
            Something went wrong
          </CardTitle>
        </CardHeader>
        <CardContent>
          Please contact Nullify with details if this issue persists
        </CardContent>
      </Card>
    </div>
  );
}
